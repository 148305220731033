import { Routes } from '@angular/router';

// Private routes
export const PRIVATE_ROUTES: Routes = [
  {
    path: 'news',
    loadChildren: () => import('../../modules/news/news.module').then(m => m.NewsModule)
  },
  {
    path: 'operator',
    loadChildren: () => import('../../modules/operator/operator.module').then(m => m.OperatorModule)
  },
  {
    path: 'calendar',
    loadChildren: () => import('../../modules/calendar/calendar.module').then(m => m.CalendarModule)
  },
  {
    path: 'program',
    loadChildren: () => import('../../modules/program/program.module').then(m => m.ProgramModule)
  },
  {
    path: 'settings',
    loadChildren: () => import('../../modules/setting/settings.module').then(m => m.SettingsModule)
  },
  {
    path: 'dashboard',
    loadChildren: () => import('../../modules/dashboard/dashboard.module').then(m => m.DashboardModule)
  },
  {
    path: 'coming-soon',
    loadChildren: () => import('../../modules/coming-soon/coming-soon.module').then(m => m.ComingSoonModule)
  }
];
