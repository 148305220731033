import { RouteInfo } from './sidebar.metadata';
import { CatalogRoles } from 'src/app/helper/catalog';

export const ROUTES: RouteInfo[] = [
    {
        path: '/dashboard', title: 'Dashboard', icon: 'fas fa-tachometer-alt', class: '', badge: '', badgeClass: 'badge badge-pill badge-danger float-right mr-1 mt-1', isExternalLink: false, submenu: [], roles: [CatalogRoles.superAdmin]
    },
    {
        path: '/news/list', title: 'Noticias', icon: 'far fa-newspaper', class: '', badge: '', badgeClass: 'badge badge-pill badge-danger float-right mr-1 mt-1', isExternalLink: false, submenu: []
    },
    {
        path: '/calendar/grid', title: 'Grilla', icon: 'far fa-calendar', class: '', badge: '', badgeClass: 'badge badge-pill badge-danger float-right mr-1 mt-1', isExternalLink: false, submenu: [], roles: [CatalogRoles.superAdmin]
    },
    {
        path: '/calendar/exceptions', title: 'Excepciones', icon: 'far fa-calendar-times', class: '', badge: '', badgeClass: 'badge badge-pill badge-danger float-right mr-1 mt-1', isExternalLink: false, submenu: [], roles: [CatalogRoles.superAdmin]
    },
    {
        path: '/program/list', title: 'Programas', icon: 'fas fa-broadcast-tower', class: '', badge: '', badgeClass: 'badge badge-pill badge-danger float-right mr-1 mt-1', isExternalLink: false, submenu: []
    },
    {
        path: '/operator/list', title: 'Operadores', icon: 'fas fa-user', class: '', badge: '', badgeClass: 'badge badge-pill badge-danger float-right mr-1 mt-1', isExternalLink: false, submenu: [], roles: [CatalogRoles.superAdmin]
    },
    {
        path: '/coming-soon', title: 'Audiencia', icon: 'fas fa-users', class: '', badge: '', badgeClass: 'badge badge-pill badge-danger float-right mr-1 mt-1', isExternalLink: false, submenu: []
    },
    {
        path: '/settings/edit', title: 'Configuraciones', icon: 'fas fa-cogs', class: '', badge: '', badgeClass: 'badge badge-pill badge-danger float-right mr-1 mt-1', isExternalLink: false, submenu: [], roles: [CatalogRoles.superAdmin]
    }
];
