import { finalize, tap } from 'rxjs/operators';
import { HttpInterceptor, HttpRequest, HttpResponse, HttpHandler, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LoaderService } from 'src/app/core/services/loader/loader.service';
import { Router } from '@angular/router';
import { AuthService } from '../core/services/auth/auth.service';
import { ToastService } from '../core/services/toast/toast.service';

@Injectable()
export class RequestInterceptor implements HttpInterceptor {

    constructor(
        private router: Router,
        private loaderService: LoaderService,
        private authService: AuthService,
        private toast: ToastService
    ) { }

    intercept(req: HttpRequest<any>, next: HttpHandler) {
        let ok: string;
        const token = JSON.parse(localStorage.getItem('m90-admin-token'));

        let headers = new HttpHeaders({
            'Content-Type': 'application/json',
            Authorization: token ? token : ''
        });

        const foreignUrl = req.url.indexOf('upload') > -1;

        if (foreignUrl) {
            headers = new HttpHeaders({
                Authorization: token ? token : ''
            });
        }

        const clonedReq = req.clone({ headers });
        return next.handle(clonedReq)
            .pipe(
                tap(
                    event => { ok = event instanceof HttpResponse ? 'succeeded' : ''; },
                    error => { ok = error, this.onSubscribeError(ok); }
                    // Log when response observable either completes or errors
                ),
                finalize(() => {
                    this.loaderService.hide();
                })
            );
    }

    private onSubscribeError(error: any) {
        if (error.status === 401 && error.error.message === 'InvalidToken') {
            this.authService.logout();
            this.router.navigate(['/login']);
            return false;
        } else {
            const message = error.error.message;
            this.toast.danger(message, 'Error!');
        }
    }
}
