export function setLocalData(key, data) {
  localStorage.setItem(key, JSON.stringify(data));
}

export function getLocalData(key) {
  return JSON.parse(localStorage.getItem(key));
}

export function removeLocalData(key) {
  return localStorage.removeItem(key);
}
