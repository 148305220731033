import { Component, OnInit, HostListener } from '@angular/core';

@Component({
  selector: 'app-private-layout',
  templateUrl: './private.component.html',
  styleUrls: ['./private.component.scss']
})
export class PrivateLayoutComponent implements OnInit {
  innerWidth: number;
  collapseWidth = 461;
  options = {
    direction: 'ltr'
  };

  constructor() {
    this.innerWidth = window.innerWidth;
  }

  ngOnInit() {
  }

  @HostListener('window:resize')
  onResize() {
    this.innerWidth = window.innerWidth;
  }

}
