import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UploadService } from 'src/app/core/services/upload/upload.service';

@Component({
  selector: 'app-input-file',
  templateUrl: './input-file.component.html',
  styleUrls: ['./input-file.component.scss']
})
export class InputFileComponent implements OnInit {
  fileName: string;

  @Input() showButton = true;
  @Input() buttonText = 'Subir imagen';
  @Input() showPreviewImage = true;
  @Input() previewUrl: string | ArrayBuffer;
  @Input() allowedMimeTypes?;

  @Output() sendFile = new EventEmitter();

  constructor(private uploadService: UploadService) { }

  ngOnInit() {
  }

  openFileBrowser(event: any) {
    event.preventDefault();
    const element: HTMLElement = document.getElementById('uploadFile') as HTMLElement;
    element.click();
  }

  onFileChange(event) {
    const file = event.target.files[0];
    this.fileName = file.name;
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = _ => {
      this.previewUrl = reader.result;
      this.uploadImage(file);
    };
  }

  uploadImage(file) {
    this.uploadService.uploadFiles(file).subscribe(
      response => {
        this.sendFile.emit(response.data['path']);
      }
    );
  }
}
