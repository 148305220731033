export const EndpointsConstants = {
  requestLimit: 20,
  shortRequestLimit: 12,
  auth: {
    base: '/auth/operator',
    login: '/login',
    forgotPassword: '/passwordrecovery',
    resetPassword: '/passwordupdate'
  },
  dashboard: {
    activeusers: '/activeusers',
    base: '/dashboard',
    conversion: '/conversion',
    program: '/program',
    programNewListeners: '/program/newlisteners',
    users: '/users',
    staff: '/staff',
    visitors: '/visitors',
    wapclicks: '/wapclicks',
    waptotalclicks: '/waptotalclicks',
  },
  operator: {
    base: '/operator',
    me: '/me'
  },
  news: {
    base: '/news'
  },
  podcast: {
    base: '/podcast'
  },
  profile: {
    base: '/profile',
    operator: '/operator'
  },
  program: {
    base: '/program'
  },
  grid: {
    base: '/grid'
  },
  exceptions: {
    base: '/exceptions'
  },
  settings: {
    base: '/settings'
  },
  upload: {
    base: '/upload',
    image: '/image'
  }
};
