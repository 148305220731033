import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpService } from '../http/http.service';
import { EndpointsConstants } from 'src/app/helper/endpoints';
import { setLocalData, removeLocalData, getLocalData } from 'src/app/helper/storage';
import { Router } from '@angular/router';
import { ToastService } from '../toast/toast.service';
import { Operator } from 'src/app/models/operator';
import { isNull } from 'util';
import { NgxPermissionsService } from 'ngx-permissions';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(
    private router: Router,
    private httpService: HttpService,
    private toast: ToastService,
    private permissionsService: NgxPermissionsService
  ) { }

  login(email: string, password: string): Observable<boolean> {
    const url = EndpointsConstants.auth.base + EndpointsConstants.auth.login;

    return new Observable<boolean>(observer => {
      this.httpService.post(url, { email, password }, true).subscribe(
        response => {
          // Flush permissions
          this.permissionsService.flushPermissions();
          // Set permissions
          this.permissionsService.loadPermissions(response.data['operator'].profiles);
          // Set login data
          this.setLoginData(response);
          observer.next(true);
        });
    });
  }

  setLoginData(response = null) {
    const token = response.data.token;
    const operator = new Operator(response.data.operator);

    this.setCurrentOperator(operator);
    setLocalData('m90-admin-token', token);

    this.toast.success('', 'Bienvenido!');
    operator.isSuperAdmin() ? this.router.navigate(['/dashboard']) : this.router.navigate(['/program', 'list']);
  }

  logout(): void {
    removeLocalData('m90-admin-currentOperator');
    removeLocalData('m90-admin-token');
    this.router.navigate(['/auth', 'login']);
  }

  isAuthenticated(redirect: boolean = true): boolean {
    const currentOperator = getLocalData('m90-admin-currentOperator');
    if (!currentOperator) {
      if (redirect) {
        this.router.navigate(['/auth', 'login']);
      }
      return false;
    }
    return true;
  }

  forgotPassword(email: string) {
    const url = EndpointsConstants.auth.base + EndpointsConstants.auth.forgotPassword;
    return this.httpService.post(url, { email });
  }

  restorePassword(password: string, token: string) {
    const url = EndpointsConstants.auth.base + EndpointsConstants.auth.resetPassword;
    const params: any = {
      newPassword: password,
      token
    };
    return this.httpService.post(url, params);
  }

  setCurrentOperator(operator: Operator) {
    setLocalData('m90-admin-currentOperator', operator);
  }

  getCurrentOperator() {
    const operator = getLocalData('m90-admin-currentOperator');
    return !isNull(operator) ? new Operator(operator) : null;
  }
}
