import { trigger, style, animate, transition } from '@angular/animations';

export const enterAnimation = trigger(
  'enterAnimation', [
    transition(':enter', [
      style({ opacity: 0 }),
      animate('10ms', style({ opacity: 1 }))
    ]),
    transition(':leave', [
      animate('500ms', style({ opacity: 0 }))
    ])
  ]
);
