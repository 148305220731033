// Modules
import { NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { NgSelectModule } from '@ng-select/ng-select';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { UiSwitchModule } from 'ngx-ui-switch';
// Components
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { FooterComponent } from './components/footer/footer.component';
import { PaginationComponent } from './components/pagination/pagination.component';
import { RouterModule } from '@angular/router';
import { FormSocialMediaComponent } from './components/form-social-media/form-social-media.component';
import { InputFileComponent } from './components/input-file/input-file.component';
import { TextEditorModule } from './modules/text-editor/text-editor.module';
import { QuillModule } from 'ngx-quill';
import { InputDateTimeComponent } from './components/input-date-time/input-date-time.component';
import { ModalSocialMediaComponent } from './components/form-social-media/modal/modal.component';
import { CopyTextComponent } from './components/copy-text/copy-text.component';

const importExport = [
  NgbModule,
  CommonModule,
  RouterModule,
  FormsModule,
  ReactiveFormsModule,
  HttpClientModule,
  NgSelectModule,
  UiSwitchModule,
  TextEditorModule,
  QuillModule
];

@NgModule({
  declarations: [
    SidebarComponent,
    NavbarComponent,
    FooterComponent,
    PaginationComponent,
    FormSocialMediaComponent,
    InputFileComponent,
    InputDateTimeComponent,
    ModalSocialMediaComponent,
    CopyTextComponent
  ],
  imports: [
    SweetAlert2Module.forRoot(),
    ...importExport
  ],
  exports: [
    SweetAlert2Module,
    SidebarComponent,
    NavbarComponent,
    FooterComponent,
    PaginationComponent,
    InputFileComponent,
    InputDateTimeComponent,
    FormSocialMediaComponent,
    CopyTextComponent,
    ...importExport
  ],
  entryComponents: [
    ModalSocialMediaComponent
  ]
})
export class SharedModule { }
