import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { Operator } from 'src/app/models/operator';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
  operator: Operator;

  constructor(private authService: AuthService) { }

  ngOnInit() {
    this.getCurrentOperator();
  }

  getCurrentOperator() {
    this.operator = this.authService.getCurrentOperator();
  }

  logout() {
    this.authService.logout();
  }
}
