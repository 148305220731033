import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { ROUTES } from './sidebar-routes.config';
import { Operator } from 'src/app/models/operator';

declare var $: any;
@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  public menuItems: any[];
  currentOperator: Operator;

  constructor(private authService: AuthService) { }

  ngOnInit() {
    $.getScript('./assets/js/app-sidebar.js');
    this.getCurrentOperator();
  }

  getCurrentOperator() {
    this.currentOperator = this.authService.getCurrentOperator();
    this.getRoutes();
  }

  getRoutes() {
    const routes = JSON.parse(JSON.stringify(ROUTES));
    this.menuItems = [];
    routes.forEach( item => {
        // Validate roles in menu
        const isValid = (item.roles) ? this.validateRoutesByProfile(item.roles) : true;
        if (isValid) {
            const menu = {
                ...item
            };
            menu.submenu = [];
            // Validate roles in submenu
            item.submenu.forEach( subItem => {
                const isSubmenuValid = (subItem.roles) ? this.validateRoutesByProfile(subItem.roles) : true;
                if (isSubmenuValid) {
                    menu.submenu.push(subItem);
                }
            });
            this.menuItems.push(menu);
        }
    });
  }

  validateRoutesByProfile(roles) {
      let isValid = false;
      this.currentOperator.profiles.forEach( profile => {
          if (roles.indexOf(profile) > -1) {
              isValid = true;
              return;
          }
      });
      return isValid;
  }
}
