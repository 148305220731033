import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { isNull } from 'util';
import { FormGroup, FormArray, AbstractControl } from '@angular/forms';

export function generateParamsForRoutes(filters: any, page: number = null, pageSize: number = null) {
    const params: any = {};
    if (!isNull(page)) { params.page = page; }
    if (!isNull(pageSize)) { params.pageSize = pageSize; }
    if (filters) {
        // tslint:disable-next-line:forin
        for (const key in filters) {
            params[key] = filters[key];
        }
    }
    return params;
}

export function isEmpty(field) {
    let valid = true;
    // Objectd
    if (typeof field === 'object') {
        for (const key in field) {
            if (field.hasOwnProperty(key)) {
                valid = false;
            }
        }
    } else if (field !== '') {
        valid = false;
    }

    // Array
    if (Array.isArray(field)) {
        if (field.length > 0) {
            valid = false;
        }
    }

    return valid;
}

export function toModelDate(date: NgbDateStruct): Date {
    return date ? new Date(date.year + '-' + date.month + '-' + date.day) : null;
}

export function isNullOrEmptyOrUndefined(field) {
    let valid = false;
    if (field === '' || field === null || field === undefined) {
        valid = true;
    }
    return valid;
}


export function generateOption(arr: any) {
    const options: any = [];
    // tslint:disable-next-line:forin
    for (const key in arr) {
        const opt = { key: '', name: '' };
        opt.key = key;
        opt.name = arr[key];
        options.push(opt);
    }
    return options;
}

export function addOrderToFilters(filters, columnSettings) {
    const column = columnSettings.find(item => item.isActive === true);
    if (!isNullOrEmptyOrUndefined(column) && !isNullOrEmptyOrUndefined(filters)) {
        // tslint:disable-next-line:no-string-literal
        filters['order'] = (column.sortAsc) ? column.sortKey + ',ASC' : column.sortKey + ',DESC';
    }
    return filters;
}

export function patchValueByItemArray(formGroup: FormGroup, items: any[], key: string, control: string) {
    const arr = [];
    items.forEach( item => {
        arr.push(item[key]);
    });
    formGroup.get(control).setValue(arr);
}

export function generateSlugValue(text) {
    const slug = text.toLowerCase().split(' ').join('-');
    return slug.normalize('NFD')
        .replace(/([^n\u0300-\u036f]|n(?!\u0303(?![\u0300-\u036f])))[\u0300-\u036f]+/gi, '$1')
        .normalize()
        .replace(/ñ/gi, 'n')
        .replace(/[^-a-z0-9]/gi, '');
}

export function sortArrayBy(array: any[], property: string) {
    array.sort( (a, b) => {
        if (a[property] < b[property]) {
            return 1;
        }
        if (a[property] > b[property]) {
            return -1;
        }
        return 0;
      });
    return array;
}

export function isString(item) {
    return typeof item === 'string' ? true : false;
}

export function getFormArray(field: string, form: FormGroup | AbstractControl) {
    return (form.get(field) as FormArray);
}
