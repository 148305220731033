import { Component, OnInit } from '@angular/core';
import { NgxPermissionsService } from 'ngx-permissions';
import { AuthService } from './core/services/auth/auth.service';
import { isNull } from 'util';
import { CatalogRoles } from './helper/catalog';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {

  constructor(
    private authService: AuthService,
    private permissionsService: NgxPermissionsService
  ) { }

  ngOnInit() {
    this.getPermissions();
  }

  getPermissions() {
    // Current operator
    const operator = this.authService.getCurrentOperator();
    // Flush permissions
    this.permissionsService.flushPermissions();
    // Set Permissions
    this.permissionsService.loadPermissions((!isNull(operator)) ? operator.profiles : [CatalogRoles.guest]);
  }
}
