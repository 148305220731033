import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-modal-social-media',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalSocialMediaComponent {
    constructor(public activeModal: NgbActiveModal) {
    }
}
