import { Component, OnInit, Input } from '@angular/core';
import { ToastService } from 'src/app/core/services/toast/toast.service';

@Component({
  selector: 'app-copy-text',
  templateUrl: './copy-text.component.html',
  styleUrls: ['./copy-text.component.scss']
})
export class CopyTextComponent implements OnInit {
  @Input() text: string;

  constructor(private toast: ToastService) { }

  ngOnInit() {
  }

  copyText() {
    const dummy = document.createElement('input');
    document.body.appendChild(dummy);
    dummy.value = this.text;
    dummy.select();
    document.execCommand('copy');
    document.body.removeChild(dummy);
    this.toast.success('Slug copiado al portapapeles');
  }

}
