import { Component, OnInit, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalSocialMediaComponent } from './modal/modal.component';

@Component({
  selector: 'app-form-social-media',
  templateUrl: './form-social-media.component.html',
  styleUrls: ['./form-social-media.component.scss']
})
export class FormSocialMediaComponent implements OnInit {
  @Input() socialMediaFormGroup: FormGroup;

  constructor(
    private modalService: NgbModal
  ) { }

  ngOnInit() {
  }

  openModal(e) {
      const modalRef = this.modalService.open(ModalSocialMediaComponent);
  }
}
