import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../shared/shared.module';
// Layouts
import { PublicLayoutComponent } from '../core/components/layouts/public/public.component';
import { PrivateLayoutComponent } from '../core/components/layouts/private/private.component';
// Services
import { Ng2IzitoastService } from 'ng2-izitoast';

@NgModule({
  declarations: [
    PublicLayoutComponent,
    PrivateLayoutComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    SharedModule
  ],
  providers: [
    Ng2IzitoastService
  ]
})
export class CoreModule { }
