export const CatalogRoles = {
    superAdmin: 'super_admin',
    director: 'director',
    staff: 'staff',
    guest: 'guest'
};

export const CatalogDashboardQueryGroup = {
    dateHour: 'dateHour',
    date: 'date',
    yearWeek: 'yearWeek',
    yearMonth: 'yearMonth'
};

export enum CatalogApiScopes {
    socialMedia = 'socialMedia',
    program = 'program',
    programs = 'programs',
    operator = 'operator',
    programMembers = 'programMembers'
}

export enum CatalogSelectSex {
    'M' = 'Masculino',
    'F' = 'Femenino',
    'NE' = 'No especifica'
}
