import { Injectable } from '@angular/core';
import { HttpService } from '../http/http.service';
import { Observable } from 'rxjs';
import { BaseResponse } from 'src/app/models/base-response';
import { EndpointsConstants } from 'src/app/helper/endpoints';
import { UploadedFile } from 'src/app/models/uploaded-file';

@Injectable({
  providedIn: 'root'
})
export class UploadService {
  url: string;

  constructor(private httpService: HttpService) {
    this.url = EndpointsConstants.upload.base + EndpointsConstants.upload.image;
  }

  uploadFiles(file, showLoader = true): Observable<BaseResponse<UploadedFile>> {
    const formData: FormData = new FormData();
    formData.append('file', file);
    return this.httpService.post(this.url, formData, showLoader);
  }
}
