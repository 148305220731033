import { Component, Input, Output, EventEmitter } from '@angular/core';
import { NgbPaginationConfig } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-pagination',
    templateUrl: './pagination.component.html',
    styleUrls: ['./pagination.component.scss'],
    providers: [
        NgbPaginationConfig
    ]
})

export class PaginationComponent {
    @Input() totalRecords: number;
    @Input() page: number;
    @Input() perPage: number;
    @Output() changePageEvent = new EventEmitter();

    constructor(config: NgbPaginationConfig) {
        config.boundaryLinks = true;
    }

    onPager(event) {
        if (!Number.isNaN(event)) {
            this.changePageEvent.emit(event);
        }
    }
}
