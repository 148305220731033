import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

// Routes
import { PUBLIC_ROUTES } from './shared/routes/public.routes';
import { PRIVATE_ROUTES } from './shared/routes/private.routes';

// Layouts
import { PublicLayoutComponent } from './core/components/layouts/public/public.component';
import { PrivateLayoutComponent } from './core/components/layouts/private/private.component';

// CanActivate
import { AuthGuard } from './core/services/auth-guard/auth-guard.service';

const routes: Routes = [
  { path: '', redirectTo: 'auth/login', pathMatch: 'full' },
  { path: '', component: PublicLayoutComponent, children: PUBLIC_ROUTES },
  { path: '', component: PrivateLayoutComponent, children: PRIVATE_ROUTES, canActivate: [AuthGuard] },
  { path: '**', redirectTo: '/error/404' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'top' })],
  exports: [RouterModule]
})

export class AppRoutingModule {

}
