import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { isNull } from 'util';

@Component({
  selector: 'app-text-editor',
  templateUrl: './editor.component.html',
  styleUrls: ['./editor.component.scss']
})
export class TextEditorComponent implements OnInit, OnChanges {
  editor;

  @Input() editorFormGroup: FormGroup;
  @Input() editorFormControlName: string;

  constructor() { }

  ngOnInit() {
  }

  ngOnChanges() {

  }

  onEditorCreated(quill) {
    this.editor = quill;
  }

  addLabel(label: string) {
    const cursorPosition = (!isNull(this.editor.getSelection())) ? this.editor.getSelection().index : 0;
    const addLabel = `{${label}}`;
    this.editor.insertText(cursorPosition, addLabel);
    this.editor.setSelection(cursorPosition + addLabel.length);
  }
}
